<template>
    <div class="order-basic">
        <div class="basic-title">用户信息</div>
        <!--  -->
        <div class="basic-user basic-content">
            <div class="user-name">
                <span>用户名：</span>
                <span>{{detail.userName}}</span>
            </div>
            <div class="user-phone">
                <span>联系电话：</span>
                <span>{{detail.userPhone}}</span>
            </div>
        </div>
        <div class="basic-content">
            <span>地址：</span>
            <span>{{detail.userAddress}}</span>
        </div>
        <div class="basic-title">订单详情</div>
        <div class="basic-content basic-detail">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            detail: Object
        }
    }
</script>

<style scoped>
.basic-title {
    padding: 16px 20px;
    background-color: #e4e7ed;
    font-size: 18px;
}
.basic-content {
    padding: 16px 20px;
}
.basic-user {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.basic-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
}
.basic-detail > :nth-child(even) {
    justify-self: end;
}
</style>